import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { Line } from "./Line";

import { Spinner } from "../../../common";
import { QuotesApi } from "../../../api";

import { useNotificationStore } from "../../../store/notifications";

const initialLine = {
  id: null,
  zone: "1",
  zoneLabel: "",
  type: "",
  description: "",
  quantity: 1,
  lengthMeasurement: "",
  dismantle: "",
  hireFee: "",
  total: 0,
};

export const QuoteLines = ({
  quoteLines,
  setQuoteLines,
  zoneOptions,
  zoneLabels,
  rates,
  formType,
  estimatedWay
}) => {
  const deleteQuoteLineMutation = QuotesApi.useDeleteLine();
  const { addNotification } = useNotificationStore();
  // Recalculate values on rate change

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      const update = quoteLines.map((line) => {
        const ErectFeeRate = rates.find(e => e.service === line.type)?.erect_fee;
        const hireFeeRate = rates.find(e => e.service === line.type)?.hire_fee;
        const erectDismantleFee = Number(ErectFeeRate * line.quantity);
        const weekHireFee = Number(Number(hireFeeRate) * line.quantity).toFixed(2);
        const total = (Number(erectDismantleFee) + Number(weekHireFee));

        return {
          ...line,
          dismantle: erectDismantleFee,
          hireFee: weekHireFee,
          total,
        };
      });

      setQuoteLines(update);
    }
    return () => {
      isCurrent = false;
    };
  }, [rates]);

  const handleAddLine = () => {
    setQuoteLines([...quoteLines, initialLine]);
  };

  const handleRemoveLine = async (id, quoteId) => {
    setQuoteLines(quoteLines.filter((line, index) => index !== id));

    // If form type is edit, send an api call to delete line by id.
    if (formType === "edit") {
      console.log("DELETING LINES", quoteId);
      try {
        await deleteQuoteLineMutation.mutateAsync(quoteId);

        addNotification({
          isSuccess: true,
          heading: "Success!",
          content: `Successfully remove quote line`,
        });
      } catch (err) {
        console.log("ERROR DELETING", err);

        addNotification({
          isSuccess: false,
          heading: "Failed!",
          content: `Failed to remove quote line`,
        });
      }
    }
  };

  const handleDimensionsLineChange = (
    index,
    quantity,
    rateData
  ) => {
    if (quantity && estimatedWay === "Meters") {
      const erectFeeRate = rateData?.erect_fee;
      const hireFeeRate = rateData?.hire_fee;
      const erectDismantleFee = Number(erectFeeRate * quantity);
      const weekHireFee = Number(Number(hireFeeRate) * quantity).toFixed(2);
      const total = (Number(erectDismantleFee) + Number(weekHireFee));

      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              dismantle: erectDismantleFee,
              hireFee: weekHireFee,
              total,
            };
          }
          return item;
        }),
      );
      console.log(quoteLines)
    }
  };

  const handleProductLineChange = (index, name, value) => {
    setQuoteLines(
      quoteLines.map((item, i) => {
        if (i === index) {
          return { ...item, [name]: value };
        }
        return item;
      }),
    );
  };

  const columns = [
    "Zones",
    "Zone Label",
    "Type",
    "Description",
    "Quantity",
    "Erect & Dismantle (p/u)",
    "Weekly Hire Fee (p/u)",
    "Total",
    "",
  ];

  return (
    <div>
      <div className="w-full">
        <h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
          Scaffolding, Propping & Optional Extras
        </h2>
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              {columns.map((column, index) => (
                <React.Fragment key={index}>
                  <th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider">
                    {column}
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 w-8">
            {quoteLines.map((line, index) => (
              <Line
                quoteLine={line}
                handleRemove={handleRemoveLine}
                key={index}
                index={index}
                handleProductLineChange={handleProductLineChange}
                handleDimensionsLineChange={handleDimensionsLineChange}
                zoneOptions={zoneOptions}
                zoneLabels={zoneLabels}
                rates={rates}
                estimatedWay={estimatedWay}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 mb-16">
        <button type="button" className="flex items-center" onClick={handleAddLine}>
          <PlusIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
          <span className="ml-2 text-sm">Add Item</span>
        </button>
      </div>
    </div>
  );
};
