import React from "react";

import { Document, Page, Image, View, Text, StyleSheet } from "@react-pdf/renderer";

import TermsOfTrade1 from "../../../TermsOfTrade1.jpg";
import TermsOfTrade2 from "../../../TermsOfTrade2.jpg";

import { Table } from "./Table/Table";
import { Description } from "./Description";
import { Terms } from "./Terms";

import { quoteColumns, summaryCostColumns, additionalItemsColumns } from "./columns";
import { Heading, ClientInfo } from "./Heading";

export function Output({ quote }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Heading quote={quote} />
        <Text style={styles.quoteText}>Quote</Text>
        <ClientInfo quote={quote} />
        <Description description={quote.description} />
        <Text style={styles.heading}>Line Items</Text>
        <Table invoice={quote.quote_lines} columns={quoteColumns} groupable />
        {quote?.quote_addons.length ? (
          <View>
            <Text style={styles.heading}>Additional Items</Text>
            <Table
              type="Additional"
              invoice={quote.quote_addons}
              columns={additionalItemsColumns}
            />
          </View>
        ) : null}
        <Table type="Summary" invoice={quote.summaryCosts} columns={summaryCostColumns} />
        <View style={styles.footer} fixed>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 6,
              width: "80%",
              paddingRight: 100,
            }}
            fixed
          >
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>COMMUNICATION</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CREDIBILITY</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CARE</Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View wrap={false}>
          <Terms terms={quote.terms} />
        </View>
        <View wrap={false}>
          <Text style={styles.subTextMarginAdditional}>
            We trust we have interpreted your requirements correctly. Please contact us if you
            require any further information or clarification.
          </Text>
          <Text style={styles.subTextMarginAdditional}>
            Regards,{`\n`}Chanh Huynh{`\n`}Unity Scaffolding Ltd
          </Text>
        </View>

        <View style={styles.footer} fixed>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 6,
              width: "80%",
              paddingRight: 100,
            }}
            fixed
          >
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>COMMUNICATION</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CREDIBILITY</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CARE</Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageTermsOfTrade}>
          <Image style={styles.pageTermsOfTrade} src={TermsOfTrade1} />
        </View>
        <View style={styles.footer} fixed>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 6,
              width: "80%",
              paddingRight: 100,
            }}
            fixed
          >
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>COMMUNICATION</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CREDIBILITY</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CARE</Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageTermsOfTrade}>
          <Image style={styles.pageTermsOfTrade} src={TermsOfTrade2} />
        </View>
        <View style={styles.footer} fixed>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 6,
              width: "80%",
              paddingRight: 100,
            }}
            fixed
          >
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>COMMUNICATION</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CREDIBILITY</Text>
            <Text style={{ fontWeight: "bold", color: "#1721ad" }}>CARE</Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    // paddingTop: 10,
    paddingBottom: 40,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "left",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#1721ad",
    marginTop: 8,
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headingContact: {
    textAlign: "right",
    marginTop: 20,
  },
  headingDisplay: {
    display: "flex",
    flexDirection: "row",
  },
  headingText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 7,
  },
  quoteText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 18,
  },
  subText: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    // fontWeight: "semibold",
  },
  subTextMargin: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    marginTop: 15,
    // fontWeight: "semibold",
  },
  subTextMarginAdditional: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    marginTop: 15,
  },
  subTextBold: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
  },
  logo: {
    objectFit: "contain",
    width: "20%",
    height: "auto",
    paddingTop: 20,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    right: 0,
  },
  pageTermsOfTrade: {
    objectFit: "contain",
    width: "105%",
    height: "auto",
    marginLeft: "-20",
  },
  footerLogo: {
    objectFit: "contain",
    width: "110%",
    // height: "auto",
    marginRight: 0,
  },

  footerText: {
    fontSize: 6,
    paddingLeft: 10,
  },

  pageNumber: {
    // position: "absolute",
    marginTop: 10,
    marginRight: 17,
    // paddingLeft: 70,
    fontSize: 8,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: "center",
    color: "grey",
  },
});
