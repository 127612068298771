import { useState } from "react";
import moment from "moment";
import { DateSelect, Button, ConfirmationDialog } from "../../common";
import { QuotesApi, WeeklyHireApi } from "../../api";

export const EndOfMonth = ({ invoicesSelected }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
    const updateHireMutation = WeeklyHireApi.useUpdateHire();
    const createHireMutation = WeeklyHireApi.useCreateHire();

    const endOfMouth = () => {
        if (!invoicesSelected) {
            return
        }

        invoicesSelected.map(async (row) => {

            const duplicatedInvoice = {
                job_id: row.job_id || "",
                zone: row.zone || "",
                zone_label: row.zone_label || "",
                type: row.type || "",
                description: row.description || "",
                on_hire: row.on_hire || "",
                completed: row.completed || 0,
                // Start hire on the completed duplicate entry, otherwise use same date on hire.
                date_on_hire: moment(date, "DD/MM/YYYY").add(1, "day").format("DD/MM/YYYY"),
                weekly_hire_rate: row.weekly_hire_rate,
                task_id: row.task_id || null
            };
            console.log(duplicatedInvoice)
            try {
                await createHireMutation.mutateAsync(duplicatedInvoice);
            } catch (err) {
                console.log("ERROR DUPLICATING INVOICE", err);
            }

            try {
                updateHireMutation.mutateAsync({
                    hire: {
                        completed_date: moment(date, "DD/MM/YYYY").format("DD/MM/YYYY"),
                        status: "Approved"
                    }, hireId: row.id
                })
            } catch (error) {
                console.log("ERROR UPDATING INVOICE", error);
            }
            
        });
    };

    const handleInputChange = (id, val) => {
        setDate(moment(val).format("DD/MM/YYYY"))
    }

    return (
        <ConfirmationDialog
            isDone={updateHireMutation.isSuccess}
            icon="info"
            title="End Of Month"
            body="This action will create a new weekly hire invoices. Select A Date for the process"
            triggerButton={
                <Button label="End Of The Month"
                    style={{ backgroundColor: "#0078d4", borderRadius:"4px" }}
                    icon="submit"
                    className="p-button-raised p-3 flex align-items-center justify-content-center"
                >
                    End Of The Month
                </Button>
            }
            confirmButton={
                <Button
                    isLoading={isLoading}
                    variant="approve"
                    onClick={async (e) => {
                        setIsLoading(true);
                        endOfMouth();
                        setIsLoading(false);
                    }}
                >
                    End Of The Month
                </Button >
            }
        >
            <div className="flex">

                <DateSelect
                    title="Date End Of Month"
                    id="date"
                    value={date}
                    onChange={handleInputChange}
                />

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </ConfirmationDialog >
    );
};