import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import { fetchQuote } from "../../../api/Quotes";

import { Spinner } from "../../../common";

import { Output } from "./Output";

export function QuotePdf() {
  const [quote, setQuote] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { quoteId } = useParams(0);

  useEffect(async () => {
    if (quoteId) {
      const quoteData = await fetchQuote(quoteId);
      let total = 0;
      let totalErectDismantle = 0;
      let WeeklyHireFees = 0;
      let totalAddOns = 0;
      for (const row of quoteData.quote_lines) {
        totalErectDismantle += Number(row.erect_dismantle || 0);
        WeeklyHireFees += Number(row.weekly_fee || 0);
      }
      if (quoteData?.quote_addons?.length) {
        for (const row of quoteData.quote_addons) {
          totalAddOns += Number(row.total || 0);
        }
      }
      total = totalErectDismantle + WeeklyHireFees + totalAddOns;

      quoteData.summaryCosts = [
        {
          id: 1,
          summaryConcept: "Total Weekly Amount",
          summaryTotalCost: WeeklyHireFees,
        },
        {
          id: 2,
          summaryConcept: "Total Erect and Dismantle",
          summaryTotalCost: totalErectDismantle,
        },
        {
          id: 3,
          summaryConcept: "Total Additions",
          summaryTotalCost: totalAddOns,
        },
        {
          id: 4,
          summaryConcept: "Total Amount including GST",
          summaryTotalCost: total + total * 0.15,
        },
      ];

      setQuote(quoteData);
      setIsLoading(false);
    }
  }, [quoteId]);

  if (isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }
  return (
    <div>
      <PDFViewer width="100%" height="1000">
        <Output quote={quote} />
      </PDFViewer>
    </div>
  );
}
