/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { RadioButton } from "primereact/radiobutton";
import { useParams, useHistory } from "react-router-dom";
import { Formik } from "formik";
import clsx from "clsx";
import supabase from "../../api/supabase";

import { ClientForm } from "../Clients";
import { ContactForm } from "../Contacts";
import { Input, TextArea, Dropdown, Address, QuoteTab, Button, Spinner } from "../../common";

import { QuoteLines } from "./QuoteLines";
import { Rates } from "./Rates/Rates";
import { AdditionalItems } from "./AdditionalItems/AdditionalItems";
import { Totals } from "./QuoteTotals";
import { useClients } from "../../api/Clients";
import { useActiveStaff } from "../../api/Staff";
import { useJobs } from "../../api/Jobs";
import { useFetchQuote } from "../../api/Quotes";
import { fetchAllContacts } from "../../api/ClientContacts";

import { useNotificationStore } from "../../store/notifications";

import {
  formatAddons,
  formatQuoteLines,
  formatQuotePortalLines,
  formatPortalAddons,
  formatZones,
  formatRates,
  tabs,
  zoneOptions,
  quoteRates,
  quoteTerms,
} from "./utils";

import { QuotesApi } from "../../api";

export const quoteTypeOptions = [
  { value: "New", label: "New" },
  { value: "Variation", label: "Variation" },
];

export const clientTypeOptions = [
  { value: "Commercial", label: "Commercial" },
  { value: "Construction", label: "Construction" },
  { value: "Civil", label: "Civil" },
  { value: "Residential", label: "Residential" }
];

const brandingOptions = [
  { value: "Unity", label: "Unity" }
];

const estimatedWayOptions = [
  { value: "Meters", label: "Meters" },
];

export function QuoteForm({ formType = "create" }) {
  const history = useHistory();
  const [redirectOutput, setRedirectOutput] = useState(false);
  const { addNotification } = useNotificationStore();

  const user = supabase.auth.user();

  const [loggedUser, setLoggedUser] = useState(null);

  // Client Form
  const [clientForm, setClientForm] = useState(false);
  const [clientId, setClientId] = useState(null);

  // Contact Form
  const [contactForm, setContactForm] = useState(false);
  const [contactId, setContactId] = useState(null);

  const [transportRate, setTransportRate] = useState(0);
  const [fetchedQuote, setFetchedQuote] = useState([]);
  const [quoteCreating, setQuoteCreating] = useState(false);

  // Quote Lines
  const [estimatedWay, setEstimatedWay] = useState("Meters");
  const [quoteLines, setQuoteLines] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);

  const [termValues, setTermValues] = useState({
    standardRate: 70,
    nonStandardRate: 100,
    smallTruck: 200,
    hiabTruck: 300
  });

  // Quote Zones
  const [zoneValues, setZoneValues] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([{ id: 1, label: "Scaffold", zone_id: "" }]);
  const [zones, setZones] = useState(null);

  // Totals
  const [weekTotal, setWeekTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [transportTotal, setTransportTotal] = useState(0);
  const [erectDismantleTotal, setErectDismantleTotal] = useState(0);
  const [additionalTotal, setAdditionalTotal] = useState(0);

  // Curent Quote Tab
  const [currentTab, setCurrentTab] = useState("Zones");

  const [quoteNum, setQuoteNum] = useState(null);
  const [client, setClient] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [rates, setRates] = useState([]);
  const clientData = useClients();
  const staffData = useActiveStaff();
  const jobData = useJobs();

  const { quoteId } = useParams();

  useEffect(async () => {
    if (formType === "create") {
      const ratesData = await QuotesApi.fetchRates();
      setRates(ratesData);
    }

    // Generate next quote #
    const quotes = await QuotesApi.getQuoteNum();

    const lastQuoteNum = quotes?.quote_num?.split("-")?.[0];
    const nextNumSeq = lastQuoteNum ? Number(lastQuoteNum) + 1 : 1000;

    setQuoteNum(`${String(nextNumSeq)}-1`);
    handleZoneChange(1);
  }, []);

  useEffect(() => {
    renderClientList();
  }, [clientId]);

  useEffect(async () => {
    if (contactId && clientId) {
      const contactsRes = await fetchAllContacts(clientId);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [contactId]);

  useEffect(async () => {
    let isCurrent = true;

    if (quoteId) {
      const quote = await QuotesApi.fetchQuote(quoteId);
      setZones(quote?.quote_zones?.length);

      if (isCurrent) {
        const contactsRes = quote?.client ? await fetchAllContacts(quote?.client) : null;
        let contacts;
        if (contactsRes && contactsRes.length > 0) {
          contacts = contactsRes.map((contact) => ({
            label: contact.name,
            value: contact.id,
          }));
        } else {
          contacts = [];
        }

        setContactList(contacts);
        setFetchedQuote(quote);
        setRates(quote.quote_rates);

        const formatQuoteLines = formatQuotePortalLines(quote?.quote_lines);
        const formQuoteAddons = formatPortalAddons(quote?.quote_addons);
        const zoneLineItems = zoneOptions.slice(0, quote?.quote_zones.length);

        const labelsArr = quote?.quote_zones.map((item, index) => ({
          zone_id: item.id,
          id: item.zone_id,
          label: String(item.zone_label).trim(),
        }));

        setZoneLabels(labelsArr);
        setEstimatedWay(quote.estimatedWay);
        setZoneValues(zoneLineItems);
        setQuoteLines(formatQuoteLines);
        setAdditionalItems(formQuoteAddons);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [quoteId]);

  useEffect(async () => {
    if (client) {
      const contactsRes = await fetchAllContacts(client);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [client]);

  useEffect(() => {
    let totalWeek = 0;
    let totalAmount = 0;
    let totalAdditionalItems = 0;
    let totalErectDismantle = 0;

    if (quoteLines.length) {
      totalWeek += quoteLines.reduce((acc, curr) => acc + Number(curr.hireFee), 0);
      totalErectDismantle += quoteLines.reduce((acc, curr) => acc + Number(curr.dismantle), 0);
    }
    if (additionalItems.length) {
      totalAdditionalItems += additionalItems.reduce((acc, curr) => acc + Number(curr.totalCost), 0);
    }

    totalAmount += (totalErectDismantle + totalAdditionalItems + totalWeek);

    setTotal(totalAmount);
    setWeekTotal(totalWeek);
    setErectDismantleTotal(totalErectDismantle);
    setAdditionalTotal(totalAdditionalItems);
  }, [quoteLines, additionalItems, erectDismantleTotal, additionalTotal]);

  // MUTATIONS
  const updateQuoteMutation = QuotesApi.useUpdateQuote();
  const updateZonesMutation = QuotesApi.useUpdateZones();
  const updateLinesMutation = QuotesApi.useUpdateLines();
  const updateAddonsMutation = QuotesApi.useUpdateAddons();
  const updateRatesMutation = QuotesApi.useUpdateRates();

  const createQuoteMutation = QuotesApi.useCreateQuote();
  const createZonesMutation = QuotesApi.useCreateZones();
  const createLinesMutation = QuotesApi.useCreateLines();
  const createAddonsMutation = QuotesApi.useCreateAddons();
  const createRatesMutation = QuotesApi.useCreateRates();

  const renderClientList = () => {
    if (clientData && clientData?.data?.length > 0) {
      return clientData.data.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
    }
    return [];
  };

  const renderStaffList = () => {
    if (staffData && staffData?.data?.length > 0) {
      return staffData.data.map((staff) => ({
        label: staff.staff_name,
        value: staff.id,
        email: staff.email,
      }));
    }
    return [];
  };

  const renderJobList = () => {
    if (jobData && jobData?.data?.length > 0) {
      return jobData.data.map((job) => ({
        label: `${job.id + 1000} - ${job.site}`,
        value: job.id,
      }));
    }
    return [];
  };

  const renderContactList = () => { };

  const handleZoneChange = (val) => {
    const zoneVal = Number(val);

    const labelsArr = Array(zoneVal)
      .fill()
      .map((obj, index) => ({
        zone_id: zoneLabels[index]?.zone_id || "",
        id: index + 1,
        label: zoneLabels[index]?.label || "",
      }));
    setZoneLabels(labelsArr);
    setZones(val);

    const zoneLineItems = zoneOptions.slice(0, zoneVal);
    setZoneValues(zoneLineItems);
  };

  const handleZoneLabelChange = (val, index) => {
    setZoneLabels(
      zoneLabels.map((item) => {
        if (index === Number(item.id)) {
          return { ...item, label: val.target.value };
        }
        return item;
      }),
    );
  };

  const loggedInUser = () => {
    const staffArr = staffData.data.find((staff) => staff.email === user?.email);
    return staffArr?.id;
  };

  if (!rates.length || quoteNum === null) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (quoteId && !fetchedQuote.id)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );

  return (
    <div className="mb-10">
      <div className="">
        <Formik
          initialValues={{
            quote_type: "New",
            PO_Number: "",
            variation_job_id: fetchedQuote.variation_job_id || "",
            quote_num: formType === "edit" ? `${fetchedQuote.quote_num}` : `${quoteNum}`,
            branding: fetchedQuote.branding || "Unity",
            clientType: fetchedQuote.clientType || "Residential",
            client: fetchedQuote.client || clientId,
            version: fetchedQuote?.version || 1,
            estimator: fetchedQuote?.estimator || 8,
            contact: fetchedQuote.contact_id || contactId,
            maxZones: fetchedQuote.max_zones ? String(fetchedQuote.max_zones) : "1",
            description: fetchedQuote.description || `Scaffolding Requirement:
      Safe access to the outer external building perimeter and roof edge protection. Ladder access and kickboards will be provided.
      We are within 4m of the Public power line and will require to work in with Vector and get a close approach consent and build in accordance to their requirements.
      There are ?x private power line to the building, we must work in accordance the attached Worksafe guideline ‘Working near low voltage overhead electric lines’. what this means for you is:
      After our quote is accepted; the owner, Client (you), UNITY and our electrician must have a pre-start site meeting to go through the close approach consent form (UNITY will facilitate) and we all must sign in agreement of what is expected from everyone and that the owner consents to our close approach.
      We will arrange a vector to turn off the power to install/ remove the electrical sleeves and so we can erect/ dismantle the scaffold that is within 4m.
`,
            street: fetchedQuote?.street_1 || "",
            street2: fetchedQuote?.street_2 || "",
            city: fetchedQuote?.city || "",
            postal: fetchedQuote.post_code || "",
            transport_total: fetchedQuote || transportRate,
            terms: fetchedQuote?.terms || quoteTerms,
            status: fetchedQuote?.status || "Pending",
            estimatedWay: fetchedQuote?.estimatedWay || "Meters"
          }}
          validate={(values) => {
            const errors = {};
            if (values.quote_type === "Variation" && !values.PO_Number) {
              errors.PO_Number = "PO Number is required.";
            }
            if (values.quote_type === "Variation" && !values.variation_job_id) {
              errors.variation_job_id = "Job is required.";
            }
            if (!values.estimatedWay) {
              errors.estimatedWay = "How will this job be estimated? is required";
            }

            /*
            if (!values.quote_num) {
              errors.quote_num = "Quote # is required.";
            }
            if (!values.client) {
              errors.client = "Client is required.";
            }
            if (!values.estimator) {
              errors.estimator = "Estimator is required.";
            }
            */
            // const zoneEmpty = zoneLabels.find(e => e.label === "");

            // if (zoneEmpty !== undefined) {
            //   errors.zoneLabels = "Is required."
            // }

            return errors;

          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.time("CREATING");
            setQuoteCreating(true);
            const {
              quote_type,
              PO_Number,
              variation_job_id,
              quote_num,
              client,
              version,
              contact,
              estimator,
              clientType,
              maxZones,
              description,
              street,
              street2,
              city,
              postal,
              terms,
              status,
              estimatedWay
            } = values;

            const quotePayload = {
              quote_type,
              PO_Number,
              variation_job_id: variation_job_id || null,
              quote_num,
              version,
              clientType,
              branding: values.branding || "",
              client: client || null,
              contact_id: contact || null,
              estimator: estimator || null,
              created_by: user?.user_metadata?.name,
              max_zones: maxZones,
              description,
              street_1: street,
              street_2: street2,
              city,
              post_code: postal,
              terms: String(terms),
              transport_total: Number(transportTotal),
              weekly_total: Number(weekTotal) || null,
              total_amount: Number(total) || null,
              status,
              estimatedWay,
              additionalTotal: Number(additionalTotal),
              erectDismantleTotal: Number(erectDismantleTotal)
            };
            if (formType === "edit") {
              try {
                console.time("TIMER");
                const quoteResult = await updateQuoteMutation.mutateAsync(
                  {
                    quote: quotePayload,
                    quoteId: fetchedQuote?.id,
                  },
                  {
                    onSuccess: (payload) => {
                      const zones = formatZones(zoneLabels, fetchedQuote?.id, "edit");
                      const lines = formatQuoteLines(quoteLines, fetchedQuote?.id, "edit");
                      const addons = formatAddons(additionalItems, fetchedQuote?.id, "edit");
                      const formatedRates = formatRates(rates, fetchedQuote?.id, "edit");

                      updateZonesMutation.mutate(zones, {
                        onSuccess: (payload) => { },
                        onError: (error) => console.log("error", error),
                      });

                      updateLinesMutation.mutate(lines, {
                        onSuccess: (payload) => { },
                        onError: (error) => console.log("error", error),
                      });

                      updateRatesMutation.mutate(formatedRates, {
                        onError: (error) => console.log("error", error),
                      });

                      updateAddonsMutation.mutate(addons, {
                        onSuccess: (payload) => {
                          setQuoteCreating(false);

                          if (redirectOutput) {
                            history.push(`/quotes/${fetchedQuote?.id}/output`);
                          } else {
                            history.push("/quotes");
                          }

                          addNotification({
                            isSuccess: true,
                            heading: "Success!",
                            content: `Successfully updated quote!`,
                          });
                        },
                        onError: (error) => {
                          setQuoteCreating(false);

                          history.push("/quotes");
                          addNotification({
                            isSuccess: false,
                            heading: "Failure!",
                            content: `Failed to update quote. ${error?.message}`,
                          });
                        },
                      });
                    },
                    onError: (error) => console.log("error", error),
                  },
                );
              } catch (err) {
                console.log("error", err);
              }
            } else {
              createQuoteMutation.mutate(quotePayload, {
                onSuccess: (payload) => {
                  const quoteId = payload?.[0]?.id;

                  // Format payloads
                  const zones = formatZones(zoneLabels, quoteId);
                  const addons = formatAddons(additionalItems, quoteId);
                  const lines = formatQuoteLines(quoteLines, quoteId);
                  const formatedRates = formatRates(rates, quoteId);

                  // CREATE ZONES
                  createZonesMutation.mutate(zones, {
                    onError: (error) => console.log("error", error),
                  });

                  // CREATE ZONES
                  createLinesMutation.mutate(lines, {
                    onError: (error) => console.log("error", error),
                  });

                  createRatesMutation.mutate(formatedRates, {
                    onError: (error) => console.log("error", error),
                  });

                  // CREATE QUOTE ADDONS
                  createAddonsMutation.mutate(addons, {
                    onSuccess: (payload) => {
                      setQuoteCreating(false);

                      history.push(`/quotes/${quoteId}/details`);
                      addNotification({
                        isSuccess: true,
                        heading: "Success!",
                        content: `Successfully created quote!`,
                      });
                    },
                    onError: (err) => {
                      setQuoteCreating(false);

                      // history.push("/quotes");
                      addNotification({
                        isSuccess: false,
                        heading: "Failure!",
                        content: `Failed to create quote. ${err?.message}`,
                      });
                    },
                  });
                },
                onError: (err) => {
                  setQuoteCreating(false);
                },
              });
            }
            console.timeEnd("CREATING");
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <div className="w-full">
              <div className="flex">
                <form onSubmit={handleSubmit} id="quoteForm" className="w-4/6">
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="New / Variation"
                        id="quote_type"
                        value={values.quote_type}
                        onChange={(opt, value) => {
                          setFieldValue("quote_type", value);
                          // setClientId(value);
                          // setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        options={quoteTypeOptions}
                      />
                    </div>
                  </div>

                  {values.quote_type === "Variation" && (
                    <div className="flex">
                      <div className="w-1/2">
                        <Input
                          title="PO Number"
                          id="PO_Number"
                          type="text"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.PO_Number}
                          error={errors.PO_Number}
                        />
                      </div>
                      <div className="w-1/2">
                        <Dropdown
                          label="Job"
                          id="variation_job_id"
                          value={values.variation_job_id}
                          onChange={async (opt, value) => {
                            setFieldValue("variation_job_id", value);

                            // reset field values
                            setFieldValue("client", "");
                            setFieldValue("contact", "");
                            setFieldValue("street", "");
                            setFieldValue("street2", "");
                            setFieldValue("city", "");
                            setFieldValue("postal", "");
                            // Find matching job and quote
                            const jobMatch = jobData.data.find((job) => job.id === value);

                            if (jobMatch && jobMatch.quote_id) {
                              try {
                                const { data, error } = await supabase
                                  .from("quotes")
                                  .select("*")
                                  .eq("id", Number(jobMatch.quote_id));
                                const quote = data?.[0];
                                if (quote) {
                                  const oldQuoteNumber = quote?.quote_num?.split("-");
                                  let { data } = await supabase.from("quotes");
                                  data = data.map(row => row.quote_num.split("-"));
                                  data = data.filter(e => oldQuoteNumber[0] === e[0]);

                                  setFieldValue("client", quote?.client);
                                  setFieldValue("quote_num", `${oldQuoteNumber[0]}-V${data.length}`);
                                  setClientId(quote?.client);
                                  setClient(quote?.client);
                                  setFieldValue("clientType", quote?.clientType || "");
                                  setFieldValue("maxZones", String(quote?.max_zones) || "");
                                  setFieldValue("contact", quote?.contact_id || "");
                                  setFieldValue("street", quote?.street_1 || "");
                                  setFieldValue("street2", quote?.street_2 || "");
                                  setFieldValue("city", quote?.city || "");
                                  setFieldValue("postal", quote?.post_code || "");
                                  setFieldValue("estimator", quote?.estimator || "");
                                }
                              } catch (err) {
                                console.log("error", err);
                              }
                            }

                            // setClientId(value);
                            // setClient(value);
                          }}
                          error={errors.variation_job_id}
                          onBlur={setFieldTouched}
                          options={renderJobList()}
                        />
                      </div>
                    </div>
                  )}


                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Client"
                        id="client"
                        value={values.client}
                        onChange={(opt, value) => {
                          setFieldValue("client", value);
                          setClientId(value);
                          setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        isLoading={clientData.isFetching}
                        options={renderClientList()}
                      // error={errors.client}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon className="w-6 h-6 text-indigo-500" />
                          <button
                            type="button"
                            className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
                            onClick={() => setClientForm(true)}
                          >
                            Add New Client
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label="Zones"
                        id="maxZones"
                        value={values.maxZones}
                        onChange={(opt, value) => {
                          handleZoneChange(Number(value));
                          setFieldValue("maxZones", value);
                        }}
                        onBlur={setFieldTouched}
                        options={zoneOptions}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Job Type"
                        id="clientType"
                        value={values.clientType}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        options={clientTypeOptions}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Input
                        title="Quote #"
                        id="quote_num"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.quote_num}
                      // error={errors.quote_num}
                      />
                    </div>

                    <div className="w-1/2">
                      <Dropdown
                        label="Contact"
                        id="contact"
                        value={values.contact}
                        onChange={(opt, value) => {
                          setContactId(value);
                          setFieldValue("contact", value);
                        }}
                        onBlur={setFieldTouched}
                        options={contactList}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon
                            className={clsx(
                              !clientId ? "text-indigo-100" : "text-indigo-500",
                              "w-6 h-6",
                            )}
                          />
                          <button
                            type="button"
                            className={clsx(
                              !clientId
                                ? "text-gray-200 cursor-none"
                                : "text-gray-600 hover:text-gray-800",
                              "pl-1 font-semibold leading-5 text-sm",
                            )}
                            onClick={() => setContactForm(true)}
                            disabled={!clientId}
                          >
                            Add New Contact
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <TextArea
                    title="Scope of Work"
                    id="description"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                  />
                  <div className="w-1/2">
                    <Dropdown
                      label="Estimator"
                      id="estimator"
                      // filterByEmail={values.estimator === user?.email}
                      value={values.estimator}
                      onChange={(opt, value) => {
                        setFieldValue("estimator", value);
                      }}
                      onBlur={setFieldTouched}
                      isLoading={staffData.isFetching}
                      options={renderStaffList()}
                    // error={errors.estimator}
                    />
                  </div>
                  <h3 className="text-lg px-4 leading-6 font-large">Site Address</h3>
                  <Address
                    streetId="street"
                    streetId2="street2"
                    cityId="city"
                    postalId="postal"
                    streetVal={values.street}
                    street2Val={values.street2}
                    cityVal={values.city}
                    postalVal={values.postal}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <h3 className="block text-sm font-medium text-gray-700 w-1/2 ml-4 mt-1">How will this job be estimated?</h3>
                  <div className="w-1/2 ml-4 mt-1 grid grid-cols-2 gap-2">
                    <div>
                      <RadioButton
                        inputId='howestimateMeters'
                        name='howestimateMeters'
                        value='Meters'
                        onChange={e => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked
                      /><label htmlFor='howestimateMeters' className='p-radiobutton-label ml-1'>
                        Meters
                      </label>
                    </div>
                  </div>

                </form>
                <div className="w-full">
                  <div className="w-4/6 mx-auto">
                    <div className="flex justify-start mb-4">
                      <QuoteTab tabs={tabs} handleChange={(tab) => setCurrentTab(tab)} />
                    </div>
                    {currentTab === "Zones" ? (
                      <>
                        {zones ? (
                          <div className="grid grid-cols-2">
                            {[...Array(Number(zones))].map((_, index) => (
                              <div className="flex items-center" key={index}>
                                <Input
                                  id={index + 1}
                                  title={`Zone ${index + 1}`}
                                  labelInline
                                  placeholder={`Zone ${index + 1}`}
                                  error={errors?.zoneLabels}
                                  defaultValue={
                                    formType === "edit"
                                      ? fetchedQuote?.quote_zones?.[index]?.zone_label
                                      : "Scaffold"
                                  }
                                  handleChange={(val) => handleZoneLabelChange(val, index + 1)}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>Select # of zones to continue</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <Rates
                        data={rates}
                        user={user}
                        handleChange={(data) => {
                          setRates(data);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {estimatedWay !== null && (
                <div className="px-4">
                  <QuoteLines
                    quoteLines={quoteLines}
                    setQuoteLines={setQuoteLines}
                    zoneOptions={zoneValues}
                    zoneLabels={zoneLabels}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                  <AdditionalItems
                    items={additionalItems}
                    setItems={setAdditionalItems}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                </div>
              )}


              <div className="w-3/6">
                <TextArea
                  title="Conditions and Clarifications"
                  id="terms"
                  type="text"
                  handleChange={handleChange}
                  value={values.terms}
                  rows={10}
                />
              </div>

              <Totals weekTotal={weekTotal} total={total} erectDismantleTotal={erectDismantleTotal} additionalTotal={additionalTotal} />
            </div>
          )}
        </Formik>
      </div>
      <div className="pl-4 mt-6">
        {formType === "edit" ? (
          <div className="flex space-x-4">
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && !redirectOutput}
              size="sm"
            >
              Save & Exit
            </Button>
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && redirectOutput}
              size="sm"
              onClick={() => setRedirectOutput(true)}
            >
              Save & View
            </Button>
          </div>
        ) : (
          <Button type="submit" form="quoteForm" isLoading={quoteCreating} size="sm">
            Create Quote
          </Button>
        )}
      </div>
      <ClientForm
        heading="Create Client"
        open={clientForm}
        setOpen={setClientForm}
        setClientId={setClientId}
      />
      <ContactForm
        heading="Create Contact"
        open={contactForm}
        setOpen={setContactForm}
        setContactId={setContactId}
      />
    </div>
  );
}

function formatRateText(rates) {
  console.log("RATES >>> ", rates);
  return `
  Roof structures:

  This quotation allows for plank protection to roofs, canopies, decks, verandas, or any other existing structures on which scaffolding needs to be erected. However,  assumes these structures can support the scaffold and workload. It is on the sole discretion of the client to ensure the strength of the structures by seeking engineering advice at the client’s own costs prior to erection.
  
  Working on roofs:
  
  Working on roofs can cause dents, cracks, scratches etc; we will take the utmost care to avoid this, but take no responsibility if any damage should occur, as roofs are generally not designed for being worked on.
  
  Power lines:
  
  Government regulations prohibit the erection and use of any scaffolding closer than 4m to power lines without the power lines being sleeved or disconnected and without the consent from the property owner. Unless the client otherwise advises, with acceptance of this quote the property owner automatically has given consent for work to take place near the power lines. The minimum charge for an electrician to inspect the site and sleeve/de-sleeve the lines is $250 plus GST.  All costs will be an additional charge to this quote. All users of the scaffold must follow the instructions on the Electrical Safety Tag in the vicinity of the power lines.
  
  Plants:
  
  Any plants closer than 1m to the walls need to be trimmed by the client prior to commencement of scaffolding.
  
  Satellite dishes / aerials:
  
  Scaffolding may interfere with the TV reception and/or the dish/aerial is in the way of the scaffolding. This quote does not include relocation of any sat dish/aerial. However,  is happy to assist in this matter.
  
  Ties and fixing:
  
  To secure the scaffolding wall ties need to be periodically screwed into the building structure and will be removed on dismantling. Any remedial work will be the client’s responsibility.
  
  Garages, driveways and entrances:
  
  Parts of the scaffolding will block the entrance, garage and/or driveway while the scaffolding is in place.
  
  Neighbours:
  
  If Unity Scaffolding needs to erect some scaffolding on the side of the house onto the neighbour’s ground. It is the client’s responsibility to obtain permission from the neighbours to allow  access to erect, dismantle and transport parts of scaffolding on their property prior to commencement of scaffolding erection.
  
  Car parks and driveways:
  
  Please note parts of the scaffolding will block the car parks and/or driveway while the scaffolding is in place.
  
  Engineering:
  
  Any costs of engineering for e.g. designs and/or certificates requested by the client or by any authorities are not included in this quote.
  
  Foundation ties:
  
  To secure the scaffolding plus wrapping against uplifts wall ties need to be drilled into the concrete foundation of the house where possible and will be removed on dismantling. Any remedial work when ties are removed is the client’s responsibility.
  
  Neighbouring Businesses:
  
  Scaffolding will be erected in front of neighbouring business.  It is the client’s responsibility to obtain permission from the businesses affected to allow  access to erect and dismantle the scaffolding.
  
  Please see the diagram below for an indication of the areas allowed for within this quote:
  
  Shrink-wrap:
  
  Weather protection will be provided to the walls by wrapping a structure approx. 1.5m above the gutter and approx. 1m into the roof to divert rainwater into the gutters.
  
  Please note: This price does not include the engineers report or the wrapping itself.
  
  The job must be done in two stages as scaffolding needs to be built on the lower roof for edge protection on the top roof.
  
  Windows will be kept clear of scaffolding, but where not possible, the scaffolding may need to be adjusted which will be charged at $90.00/per man/per hour plus GST.
  
  The following conditions apply to this job:
  
  Our condition on acceptance of this quote is that we will require a 100% upfront payment of the EDC.  
  
  EDC payment to be paid prior to commencement of erecting of scaffold. Balance to be paid 7 days after invoice has been sent upon removal of scaffolding.
  
  Terms:
  
  Hire charges start on the date on which the erection of the scaffolding is completed and continue until the date of dismantling of the scaffolding as advised by the client (off-hire date). Both dates will be charged as whole days.
  
  Alterations or extra work will be charged at $90.00 plus GST/hour/man plus hire charges for additional scaffolding material.  Extra work includes but is not limited to all modifications, moves, repairs, partly dismantling or adjustments of the scaffolding.
  
  Please confirm your acceptance of this Quote and enclosed Terms of Trade either online or via email reply to <infoemailhere>.
`;
}