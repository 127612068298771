import { pdf } from "@react-pdf/renderer";
import supabase from "../../../api/supabase";
import { fetchQuote } from "../../../api/Quotes";
import { DownloadPDF } from "./DownloadPDF";

export async function uploadQuote(quoteId) {
  const quoteData = await fetchQuote(quoteId);

  let totalErectDismantle = 0;
  let WeeklyHireFees = 0;
  let totalAddOns = 0;
  for (const row of quoteData.quote_lines) {
    totalErectDismantle += Number(row.erect_dismantle || 0);
    WeeklyHireFees += Number(row.weekly_fee || 0);
  }
  if (quoteData?.quote_addons?.length) {
    for (const row of quoteData.quote_addons) {
      totalAddOns += Number(row.total || 0);
    }
  }

  quoteData.summaryCosts = [
    {
      id: 1,
      summaryConcept: "Total Weekly Amount",
      summaryTotalCost: WeeklyHireFees,
    },
    {
      id: 2,
      summaryConcept: "Total Erect and Dismantle",
      summaryTotalCost: totalErectDismantle,
    },
    {
      id: 3,
      summaryConcept: "Total Additions",
      summaryTotalCost: totalAddOns,
    },
  ];

  let url = "";
  const blob = await pdf(DownloadPDF({ quote: quoteData })).toBlob();
  const fileName = `${quoteData?.quote_num} - ${Date.now()}`;

  const { data, error } = await supabase.storage.from("files").upload(`quotes/${fileName}`, blob, {
    cacheControl: "3600",
    upsert: false,
  });

  if (!error) {
    const fetchFile = await supabase.storage.from("files").getPublicUrl(`quotes/${fileName}`);
    // eslint-disable-next-line no-console
    console.log(fetchFile);
    url = fetchFile.publicURL;
  }
  return encodeURI(url);
}
