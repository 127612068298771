import { v4 as uuidv4 } from "uuid";

export function formatQuoteLines(lines, quoteId, type) {
  return lines.map((line) => ({
    ...(type === "edit" && { id: line.id || null }),
    quote_id: quoteId,
    zone: line.zone,
    zone_label: line.zoneLabel,
    type: line.type || "",
    description: line.description,
    quantity: line.quantity,
    length: line.lengthMeasurement,
    height: line.height,
    width: line.width,
    total_dimensions: line.totalDimensions || 0,
    weekly_duration: line.duration || 0,
    transport: line.transport || 0,
    erect_dismantle: line.dismantle,
    weekly_fee: line.hireFee || 0,
    total: String(line.total) || "",
  }));
}

export function formatQuotePortalLines(lines, quoteId) {
  return lines.map((item) => ({
    id: item.id,
    zone: String(item.zone).trim(),
    zoneLabel: item.zone_label,
    type: item.type,
    description: item.description,
    quantity: item.quantity || 1,
    lengthMeasurement: item.length,
    height: item.height,
    width: item.width,
    totalDimensions: item.total_dimensions || 0,
    duration: item.weekly_duration || 0,
    transport: item.transport || 0,
    dismantle: item.erect_dismantle,
    hireFee: item.weekly_fee || 0,
    total: item.total || 0,
  }));
}

export function formatZones(zones, quoteId, type = "") {
  return zones.map((zone) => ({
    ...(type === "edit" && { id: zone.zone_id || null }),
    quote: quoteId,
    zone_id: zone.id,
    zone_label: zone.label,
  }));
}

export function formatAddons(addons, quoteId) {
  return addons.map((addon) => ({
    id: addon.id,
    quote: quoteId,
    type: addon.type || "Additional",
    description: addon.description,
    duration: Number(addon.duration) || null,
    hire_fee: Number(addon.hireFee) || null,
    fixed_charge: Number(addon.fixedCharge) || null,
    total: Number(addon.totalCost) || null,
  }));
}

export function formatRates(rates, quoteId, type = "create") {
  return rates.map((rate) => ({
    ...(type === "edit" && { id: rate.id || null }),
    quote_id: quoteId,
    service: rate.service,
    erect_fee: rate.erect_fee,
    hire_fee: rate.hire_fee,
    type: rate.type
  }));
}

export function formatPortalAddons(addons) {
  return addons.map((addon) => ({
    id: addon.id,
    type: addon.type,
    description: addon.description,
    duration: addon.duration,
    hireFee: addon.hire_fee,
    fixedCharge: addon.fixed_charge,
    totalCost: Number(addon.total),
  }));
}

export const tabs = [
  { name: "Zones", href: "#", id: 0 },
  { name: "Rates", href: "#", id: 1 },
];

export const zoneOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
];

export const quoteRates = [
  {
    id: uuidv4(),
    service: "Roof",
    erect: "95.00",
    hire: "12.00",
  },
  {
    id: uuidv4(),
    service: "Propping",
    erect: "22.00",
    hire: "2.00",
  },
  {
    service: "Edge Protection",
    erect: "28.00",
    hire: "0.70",
  },
  {
    service: "Shrinkwrap",
    erect: "10.00",
    hire: "0.00",
  },
  {
    service: "Geda 1200",
    erect: "320.00",
    hire: "5.00",
  },
];

export const quoteTerms = `
Additional Conditions:
1. 50% Deposit of the Erect/ Dismantle/ Transport portion is required upon acceptance and the balance upon handover.
2. With our current workload, we can dismantle within 3 weeks from the off hire date.
3. Erect/ Dismantle/ Transport portion is required upon acceptance.
4. Respectfully, there will be no service due to any late payments. Service will resume at the next available date after the late payment is made. USL takes no responsibility for any delays that occur during this period. Hire applies during this period.
5. Minimum charge for Shrink-wrap is $950.
6. Minimum charge for scaffolding E/D/T is $1800.
7. Weekly safety checks will incur a charge of $95 plus gst per visit. All remedial work will be on charge up. Refer to our T&Cs below.
8. Water to fill Water Barrels to be provided to UNITY Scaffolding Ltd FOC.
9. No Scaffolding off balconies and internal scaffolding.
10. No Liquidated Damages.
11. No Retentions.
12. Minimum 3 weeks hire.
13. Have allowed to only erect and dismantle once. No allowances for any staging of work.
14. No allowance for working within 4m of overhead powerlines.
15. No allowance for slab edge protection
16. No allowance has been made for scrim and pedestrian protection.
17. Clear access will be required for trucks delivery/ unloading and laydown of materials.
18. We require a solid ground base to build on.
19. We require truck access to within 10m of the scaffold.
`;
